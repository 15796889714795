<template>
  <nav :class="{ 'navigation--invert': invert }" class="navigation">
    <ul class="navigation__list">
      <li class="navigation__item">
        <a @click.native="clickNav" href="/bookkeeping" class="navigation__link"
          >세무서비스</a
        >
      </li>
      <li class="navigation__item">
        <a
          href="https://www.heum.report/?utm_source=heum&utm_medium=web&utm_campaign=alfred_traffic_ua_heum-header-nav"
          target="_blank"
          class="navigation__link"
          >세무 시스템</a
        >
      </li>
      <li class="navigation__item">
        <a href="/private" class="navigation__link">컨설팅</a>
      </li>
      <li class="navigation__item">
        <a href="/contents" class="navigation__link">블로그</a>
      </li>
      <li class="navigation__item">
        <a
          href="https://www.overtax.co.kr/"
          target="_blank"
          class="navigation__link"
          >더낸세금</a
        >
      </li>
    </ul>
    <btn-consultation
      v-if="$route.name !== 'consulting' && $route.name !== 'consulting-detail'"
      @click.native="clickNav"
      custom-class="navigation__consulting"
    />
  </nav>
</template>

<script>
import BtnConsultation from '@/components/common/btn-consultation.vue'

export default {
  components: { BtnConsultation },
  props: {
    invert: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  methods: {
    clickNav() {
      this.$emit('toggleMenu')
    }
  }
}
</script>

<style lang="scss">
.navigation {
  @include clearfix;

  &--invert {
    .navigation__link {
      color: initial;
      border-color: initial;
    }

    .navigation__link.btn {
      color: #fff;
    }
  }
}

.navigation__list {
  padding: 76px 0 0 30px;

  @include av-mq(desk) {
    float: left;
    padding: 0;
  }

  &--etc {
    padding-top: 0;

    @include av-mq(desk) {
      display: none;
    }

    .navigation__item {
      @include av-mq(desk) {
        float: left;
        margin-bottom: 0;
      }
    }
  }
}

.navigation__item {
  margin-bottom: 24px;

  @include av-mq(desk) {
    float: left;
    margin: 0 22px 0 0;
    line-height: 76px;
  }

  &--hide {
    @include av-mq(desk) {
      display: none;
    }
  }
}

.navigation__link {
  display: block;
  padding: 4px 0;
  font-size: 20px;
  letter-spacing: -0.71px;
  color: $black;
  text-decoration: none;

  @include av-mq(desk) {
    padding: 0;
    font-size: 16px;
    color: #fff;
    transition: color 0.3s;
  }

  &--active {
    font-weight: bold;
  }
}

.navigation__consulting {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  right: 1rem;
  text-align: center;
  color: #fff;
  background-color: #1f75e8;
  padding: 1rem 0;
  text-decoration: none;
  border-radius: 5px;

  @include av-mq(desk) {
    display: none;
  }
}
</style>
