<template>
  <a :class="{ 'heum-logo--invert': invert }" href="/" class="heum-logo">
    <!-- svg logo -->
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32">
      <g class="heum-logo__svg">
        <path
          d="M11.353 14.9h9.224V0h-9.224zM0 31.93h9.224V0H0zM22.706 31.93h9.224V0h-9.224zM11.353 31.93h9.224v-14.9h-9.224z"
        />
      </g>
      <!--[if lt IE 9]>
        <img src="images/fallback-logo.png" alt="heumtax" />
      <![endif]-->
    </svg>
  </a>
</template>

<script>
export default {
  props: {
    invert: {
      type: Boolean,
      required: true,
      default: false
    }
  }
}
</script>

<style lang="scss">
.heum-logo {
  display: block;
  width: 32px;
  height: 32px;

  &__svg {
    fill: #fff;
    transition: fill 0.3s;
  }

  &--invert {
    .heum-logo__svg {
      fill: #002147;
    }
  }
}
</style>
